import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Layout from "../components/layout";
import SEO from "../components/seo";

function convertToSlug(Text)
  {
      return Text
          .toString()
          .toLowerCase()
          .replace(/ /g,'-')
          .replace(/[^\w-]+/g,'')
          ;
  }




const ComponentName = ({data}) => {


    const posts = data.posts;
    const category = data.category.data;
    const postTitle = `Top ${posts.totalCount} ${category.SimpleText.toString().toLowerCase()}s design for inspiration`;
    const postDescription = `${category.Description}. Find design inspiration for your ${category.SimpleText.toString().toLowerCase()}`
    return (
        <Layout logoText="Inspirations">
             <SEO 
                        description={`${postDescription}`}
                        title={`${postTitle}`} 
                        author="Athul Suresh"
                        />

                    <div className="border-b border-gray-200  bg-white shadow-sm pt-14 pb-2">
                        <div className="max-w-7xl mx-auto  px-4 lg:px-0">
                            

                            <div className="flex pt-4 pb-2 text-sm text-gray-400">
                                <a href="/email-inspirations/" className="text-gray-500 mr-2 inline-block hover:underline hover:text-gray-700 ">Inspiration</a> /  <span className="text-gray-900 ml-2 inline ">{category.Name}</span>
                            </div>

                           
                        </div>
                    </div>


            <div className=" py-10 border-b border-gray-200">
                
                <div className="max-w-7xl mx-auto text-left">
                    <h1 className="font-bold text-red-750 px-4 lg:px-0">Top <span className="underline">{category.SimpleText.toString().toLowerCase()}s</span> for design inspiration</h1>
                    <p className="mt-2 text-base text-gray-500 px-4 lg:px-0">{category.Description}</p>
                </div>
            </div>

            <div className="py-12 bg-gray-50">
                <div className="max-w-7xl mx-auto">
                    <div className="grid grid-cols-1 lg:grid-cols-4 md:grid-cols-2  gap-2 md:gap-8 px-4 lg:px-0">

                        { posts.edges.filter(a => a.node.data.Subject != null ).map(epost => (
                            <div>
                            <Link to={`/email-inspirations/${convertToSlug(epost.node.data.Name__from_Companies_)}/${convertToSlug(epost.node.data.Subject)}/`} key={epost.node.id} className="block rounded-lg border border-gray-200 shadow-sm bg-white duration-75 transform scale-100 hover:scale-105 hover:shadow-lg mb-4">
                                {epost.node.data.Attachments ? <img src={epost.node.data.Attachments[0].url} style={{ maxHeight:'400px' }} className="w-full object-cover object-top border-b border-gray-100 rounded-t-md"></img> : null }
                                <div className="px-2 py-3">
                                    <div className="text-sm">{epost.node.data.Subject}</div>
                                    <div className="text-gray-600 mt-1">{epost.node.data.Name__from_Companies_ ? epost.node.data.Name__from_Companies_[0] : null}</div>
                                </div>
                            </Link>
                            </div>
                        ))}

                       
                    </div>
                </div>
                
            </div>

            <div className="pt-20">
                <div className="max-w-5xl mx-auto px-4 md:px-0">
                    <h4 className="text-center text-base mb-4 text-gray-500">Find great email design by these companies</h4>
                    <div className="flex items-center justify-center flex-wrap">
                        { data.Allcompanies.edges.map(({node}) => (
                            <Link className="text-sm px-4 py-2 mb-4 bg-gray-50 text-gray-700 hover:text-gray-900 hover:bg-gray-200 hover:border-gray-300 border border-gray-200 rounded-full mr-2" to={`/email-inspirations/${convertToSlug(node.data.Name)}/`}>{node.data.Name}</Link>
                        ))}
                       
                    </div>
                </div> 
            </div>
        </Layout>
    )
}



export const query = graphql`
  query ($recordId: String){
    posts: allAirtable(
        filter: {table: { eq: "Emails" }, data: { EmailType: {in: [$recordId]} } }) {
      edges {
        node {
          id
          data {
            Name__from_Companies_
            Subject
            website__from_Companies_
            Company
            Attachments {
              filename
              id
              url
              thumbnails {
                large {
                  url
                }
            }
          }
        }
      }
    }
    totalCount
}


    category: airtable(recordId: { eq: $recordId }) {
        id
        data {
            SimpleText
            Name
            Description
        }
    }

    Allcompanies: allAirtable(filter: {table: { eq: "Companies" }}) {
        edges {
            node {
                id
                data {
                    Name
                    pageHeading
                }
            }
        }
    }
  }`

export default ComponentName